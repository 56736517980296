import React, { Component, useContext, useState } from "react";
import CartContext from "./CartContext";
import { BsHeart, BsFillHeartFill, BsCart2, BsBag } from "react-icons/bs";
import axios from "axios";
import ProductCardMobile from "./ProductCardMobile";
import { Helmet } from "react-helmet";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";
import Zoom from "react-medium-image-zoom";

import {
  Carousel,
  Stack,
  Form,
  Button,
  Modal,
  ListGroup,
  Badge,
  FloatingLabel,
  Row,
  Col,
  Table,
  InputGroup,
  Container as div,
} from "react-bootstrap";
import IncDecCounterMobile from "./IncDecCounterControlMobile";
export class ProductSingleProductViewMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      currentImageFileName: this.props.prod.imgFileName,
      qty: 1,
      carouselIndex: 0,
      prod: "",
      selectedCustomField1Value: "",
      selectedCustomField2Value: "",
      selectedCustomField3Value: "",
      selectedProductVariant: "",
      customValue1List: [],
      customValue2List: [],
      customValue3List: [],
      reload: true,
      activeButton: "",
    };
  }

  // OnImageChange = (e, i) => {
  //   this.setState({
  //     currentImageFileName: this.state.prod.productImages[i].fileName,
  //   });
  // };
  OnImageChange = (e, i) => {
    if (
      this.state.currentImageFileName ==
        this.state.prod.productImages[i].fileName &&
      this.state.currentPosterName ==
        this.state.prod.productImages[i].posterName
    )
      return;

    this.setState(
      {
        currentImageFileName: this.state.prod.productImages[i].fileName,
      },
      () => {
        this.setState(
          {
            currentPosterName: this.state.prod.productImages[i].posterName,
          },
          () =>
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            })
        );
      }
    );
    this.setState({
      activeButton: this.state.prod.productImages[i].fileName,
    });
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    var tempprod = JSON.parse(JSON.stringify(this.props.prod));

    if (this.state.prod.productVariantType == 2) {
      tempprod.mrp = this.state.prod.mrp;
      tempprod.discount = this.state.prod.discount;
      tempprod.name +=
        "[" +
        this.state.prod.customField1 +
        ": " +
        this.state.selectedCustomField1Value +
        "]";
      tempprod.name += this.state.prod.customField2
        ? " [" +
          this.state.prod.customField2 +
          ": " +
          this.state.selectedCustomField2Value +
          "]"
        : "";
    }
    this.context.addToMyCart({
      prod: tempprod,
      qty: this.state.qty,
    });
    // this.context.forceUpdateCartView();
    this.props.onHide();
  };

  handleQtyChange = (value) => {
    this.setState({ qty: value });
  };
  handleCarouselSelect = (selectedIndex, e) => {
    this.setState({ carouselIndex: selectedIndex });
  };

  GetProductById = (productId) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetProductById/" +
          this.props.prod.id
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ prod: response.data }, () => {
            if (response.data.productVariantType == 2) {
              this.LoadCustomValueList(
                response.data.productCustomFields.sort((a, b) =>
                  a.seqNo > b.seqNo ? 1 : -1
                )
              );
              this.setState(
                {
                  selectedCustomField1Value:
                    response.data.productCustomFields[0].customValue1,
                },
                () => this.SetSelectedProductVariant()
              );
              this.setState(
                {
                  selectedCustomField2Value:
                    response.data.productCustomFields[0].customValue2,
                },
                () => this.SetSelectedProductVariant()
              );
              this.setState(
                {
                  selectedCustomField3Value:
                    response.data.productCustomFields[0].customValue3,
                },
                () => this.SetSelectedProductVariant()
              );
            }
          });
          this.setState({
            currentImageFileName:
              response.data.productImages.length > 0
                ? response.data.productImages.filter((p) => p.seqNo === 1)[0]
                    .fileName
                : "",
          });
          this.setState({
            currentPosterName:
              response.data.productImages.length > 0
                ? response.data.productImages.filter((p) => p.seqNo === 1)[0]
                    .posterName
                : "",
          });
          this.setState({
            activeButton:
              response.data.productImages.length > 0
                ? response.data.productImages.filter((p) => p.seqNo === 1)[0]
                    .fileName
                : "",
          });
        }
      })
      .catch((error) => {
        console.error("GetProductById - There was an error! ", error);
      });
  };

  LoadCustomValueList = (productCustomFields) => {
    var list1 = [];
    productCustomFields.map((m) => {
      list1 = [...list1, { value: m.customValue1 }];
    });
    var uniquelist1 = [...new Set(list1.map((item) => item.value))];

    this.setState({ customValue1List: uniquelist1 }, () => {
      if (uniquelist1.length > 0) {
        var list2 = [];
        var fil = productCustomFields.filter(
          (f) => f.customValue1 == uniquelist1[0]
        );

        if (fil.length > 0) {
          fil.map((m) => {
            list2 = [...list2, { value: m.customValue2 }];
          });
          var uniquelist2 = [...new Set(list2.map((item) => item.value))];
          this.setState({ customValue2List: uniquelist2 });
        }
      }
    });

    console.log("hi");
  };

  SetSelectedProductVariant = () => {
    this.state.prod.productCustomFields.map((m) => {
      if (
        m.customValue1 == this.state.selectedCustomField1Value &&
        m.customValue2 == this.state.selectedCustomField2Value &&
        m.customValue3 == this.state.selectedCustomField3Value
      ) {
        this.setState({ selectedProductVariant: m }, () => {
          this.state.prod.mrp = this.state.selectedProductVariant.mrp;
          this.state.prod.discount = this.state.selectedProductVariant.discount;
          this.setState({ prod: this.state.prod });
        });
      }
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "customValue1":
        this.setState({ selectedCustomField1Value: value }, () => {
          var list2 = [];
          var fil = this.state.prod.productCustomFields.filter(
            (f) => f.customValue1 == this.state.selectedCustomField1Value
          );

          if (fil.length > 0) {
            fil.map((m) => {
              list2 = [...list2, { value: m.customValue2 }];
            });
            var uniquelist2 = [...new Set(list2.map((item) => item.value))];
            this.setState({ customValue2List: uniquelist2 }, () => {
              this.setState(
                { selectedCustomField2Value: uniquelist2[0] },
                () => {
                  this.SetSelectedProductVariant();
                }
              );
            });
          }
        });
        break;
      case "customValue2":
        this.setState({ selectedCustomField2Value: value }, () =>
          this.SetSelectedProductVariant()
        );
        break;
    }
  };
  IsAlreadyInWishlist = (prodId) => {
    var res = this.context.wishListProductIds.filter(
      (f) => f.prodId == this.props.prod.id
    );
    if (res.length > 0) return true;
    else return false;
  };
  componentDidMount = () => {
    // window.scrollTo(0, 0);
    this.GetProductById(this.props.prod.id);
  };
  IsVideo(filename) {
    if (filename) {
      var f = filename.split(".").pop();
      if (f == "mp4") return true;
    }
    return false;
  }

  render() {
    return (
      <div className="container">
        <Helmet>
          <link rel="icon" type="image/x-icon" href="dist/images/fav.ico" />
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
        </Helmet>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.props.onHide}
          dialogClassName="ProductModal "
          style={{
            backgroundColor: this.context.theme.productCardBackgroundColor,
            color: this.context.theme.productCardTextColor,
          }}
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header
              closeButton
              style={
                {
                  //backgroundColor: this.context.theme.mainNavBarBackgroundColor,
                  //color: this.context.theme.mainNavBarTextColor,
                }
              }
            ></Modal.Header>

            <Modal.Body
              className="show-grid productmodal-body"
              style={{
                backgroundColor: this.context.theme.productCardBackgroundColor,
                color: this.context.theme.productCardTextColor,
              }}
            >
              <div className="">
                <Row>
                  <Col>
                    <>
                      <div>
                        <div style={{}}>
                          <Form.Group
                            controlId="Image"
                            className="text-center "
                          >
                            <div class="item  m-0 py-0 imgcenter">
                              {/* <div className="img-hover-zoom m-0 p-0 w-100"> */}
                              {this.state.reload == true ? (
                                <>
                                  <div className=" m-0 p-0 w-100">
                                    {this.IsVideo(
                                      this.state.currentImageFileName
                                    ) == true ? (
                                      <>
                                        <video
                                          preload="none"
                                          width="100%"
                                          height="200px"
                                          controls
                                          poster={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            this.state.currentPosterName +
                                            this.context.store.storageSasToken
                                          }
                                        >
                                          <source
                                            src={
                                              this.context.store
                                                .storageBlobUrl +
                                              this.context.store
                                                .storageBlobContainerName +
                                              "/images/" +
                                              this.state.currentImageFileName +
                                              this.context.store.storageSasToken
                                            }
                                            type="video/mp4"
                                          />
                                        </video>
                                      </>
                                    ) : (
                                      <>
                                        <Zoom>
                                          <img
                                            className=" border-0 text-center align-middle imgcenter"
                                            style={{
                                              width: "100%",
                                              height:
                                                this.context.theme
                                                  .productSingleViewProductCardImageHeightMobile +
                                                "cm",
                                            }}
                                            src={
                                              this.context.store
                                                .storageBlobUrl +
                                              this.context.store
                                                .storageBlobContainerName +
                                              "/images/" +
                                              this.state.currentImageFileName +
                                              this.context.store.storageSasToken
                                            }
                                          />
                                        </Zoom>
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {this.state.prod.sku ? (
                                <>
                                  <span
                                    class="notify-badge-right_share"
                                    data-toggle="Share"
                                    // data-placement="top"
                                    title="Share me"
                                    style={{
                                      cursor: "pointer",
                                      // backgroundColor: "black",
                                    }}
                                  >
                                    <RWebShare
                                      data={{
                                        text: this.state.prod.name,
                                        url:
                                          window.location.origin +
                                          "/SingleProductViewGreenArg?sku=" +
                                          this.state.prod.sku,
                                        title: this.state.prod.name,
                                      }}
                                      onClick={() =>
                                        console.log("shared successfully!")
                                      }
                                    >
                                      <FaShareAlt size="16" />
                                    </RWebShare>
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                              {this.props.prod.shortAd ? (
                                <span
                                  className="ribbonleft ribboncorners"
                                  style={{
                                    backgroundColor:
                                      this.context.theme
                                        .mainNavBarBackgroundColor,
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                  }}
                                >
                                  {this.state.prod.shortAd}
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                        <ListGroup
                          horizontal
                          className=" p-1  thumbnaillist-group border"
                          // style={{ backgroundColor: "#F8F9FA" }}
                          style={{ backgroundColor: "transparent" }}
                        >
                          {this.state.prod != ""
                            ? this.state.prod.productImages
                                .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                .map((image, i) => (
                                  <>
                                    <div className="p-1">
                                      <ListGroup.Item
                                        id={image.fileName}
                                        className={
                                          this.state.activeButton ==
                                          image.fileName
                                            ? "active-color"
                                            : "base-class"
                                        }
                                      >
                                        <div className="m-0 p-0">
                                          {this.IsVideo(image.fileName) ==
                                          true ? (
                                            <>
                                              <img
                                                className="border-0 text-center align-top align-content-start "
                                                width="40px"
                                                height="40px"
                                                src={
                                                  this.context.store
                                                    .storageBlobUrl +
                                                  this.context.store
                                                    .storageBlobContainerName +
                                                  "/images/" +
                                                  image.posterName +
                                                  this.context.store
                                                    .storageSasToken
                                                }
                                                onClick={(e) =>
                                                  this.OnImageChange(e, i)
                                                }
                                                style={{ objectFit: "contain" }}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                className="border-0 text-center align-top align-content-start "
                                                width="40px"
                                                height="40px"
                                                src={
                                                  this.context.store
                                                    .storageBlobUrl +
                                                  this.context.store
                                                    .storageBlobContainerName +
                                                  "/images/" +
                                                  image.fileName +
                                                  this.context.store
                                                    .storageSasToken
                                                }
                                                onClick={(e) =>
                                                  this.OnImageChange(e, i)
                                                }
                                                style={{ objectFit: "contain" }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </ListGroup.Item>
                                    </div>
                                  </>
                                ))
                            : ""}
                        </ListGroup>
                      </div>
                    </>
                  </Col>
                </Row>
                <Row>
                  <Stack direction="horizontal">
                    <label
                      className="mx-0 p-0 mb-0 productNamewrap text-center "
                      style={{
                        width: "100%",
                        // height: "40pt",
                        cursor: "pointer",
                        //fontFamily: "Helvetica",
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                      onClick={(e) => this.OpenSingleView(e)}
                    >
                      <div>{this.props.prod.name}</div>
                    </label>
                    {this.props.prod.discount > 0 ? (
                      <>
                        {/* <span class="notify-badge-left"> */}
                        <span
                          // class="notify-badge-left"
                          className="ribbonmodernright"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Discount"
                          style={{
                            backgroundColor:
                              this.context.theme.mainNavBarBackgroundColor,
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                        >
                          {this.props.prod.discount}% off
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    {/* share */}
                    {/* wishlist */}
                    <span
                      // class="notify-badge-right"
                      data-toggle="tooltip"
                      // data-placement="top"
                      title="Add to Wishlist"
                      style={{
                        cursor: "pointer",
                        // backgroundColor: "black",
                      }}
                      onClick={(e) => {
                        this.context.AddToWishList(this.props.prod);
                        this.setState({ msgboxOpen: true });
                        this.setState({
                          msgboxTitle: "Information",
                        });
                        this.setState({
                          msgboxMessage: "Added to wishlist",
                        });
                      }}
                    >
                      {this.IsAlreadyInWishlist(this.props.prod.id) == false ? (
                        <>
                          <BsHeart
                            size="16"
                            className=""
                            style={{
                              cursor: "pointer",
                              backgroundColor: "transparent !important",
                              color: this.context.theme.productCardTextColor,
                            }}
                          ></BsHeart>
                        </>
                      ) : (
                        <>
                          <BsFillHeartFill
                            size="16"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "transparent !important",
                              color: "red",
                            }}
                          ></BsFillHeartFill>
                        </>
                      )}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                  </Stack>
                </Row>
                <br></br>
                <Row>
                  <Form.Label
                    className="text-center "
                    style={{ width: "100%" }}
                  >
                    {this.props.prod.discount > 0 ? (
                      <>
                        <div>
                          <span className="costfont">
                            <b
                              className=""
                              style={{
                                fontSize:
                                  this.context.theme
                                    .priceFontSizeOnProductCard + "px",
                              }}
                            >
                              {Math.round(
                                Number(
                                  this.state.prod.mrp -
                                    (this.state.prod.mrp *
                                      this.state.prod.discount) /
                                      100
                                ),
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </b>
                            &nbsp;&nbsp;
                            <strike>
                              {Math.round(
                                this.state.prod.mrp,
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </strike>
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <span className="costfont text-center">
                            <small className=" "></small>
                            <strong className=" mrpfontsize">
                              {" "}
                              {Math.round(
                                this.state.prod.mrp,
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </strong>
                          </span>
                        </div>
                        <small>&nbsp;</small>
                      </>
                    )}
                  </Form.Label>
                </Row>
              </div>
              <br />
              <Row className="">
                <Col className=" ">
                  <div className="text-dark">
                    {this.state.prod.productVariantType == 2 ? (
                      <>
                        {this.state.prod.customField1 != null &&
                        this.state.prod.customField1 != undefined ? (
                          <>
                            <h5 className="">
                              <b>{this.state.prod.customField1}</b>
                            </h5>
                            <Form.Select
                              type="text"
                              name="customValue1"
                              className="text-dark"
                              value={this.state.selectedCustomField1Value}
                              required
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              {this.state.customValue1List.map((m) => (
                                <>
                                  <option value={m}>{m}</option>
                                </>
                              ))}
                            </Form.Select>
                          </>
                        ) : (
                          <></>
                        )}
                        {this.state.prod.customField2 ? (
                          <>
                            <h5 className="font-bold text-dark">
                              <b>{this.state.prod.customField2}</b>
                            </h5>
                            <Form.Select
                              type="text"
                              name="customValue2"
                              value={this.state.selectedCustomField2Value}
                              required
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              {this.state.customValue2List.map((m) => (
                                <>
                                  <option value={m}>{m}</option>
                                </>
                              ))}
                            </Form.Select>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <div className="text-center">
                    {this.props.prod.productVariantType == 2 ? (
                      <>
                        <Button
                          className="buttonRounded buttonwithshadow"
                          variant="primary"
                          type="submit"
                          style={{
                            backgroundColor:
                              this.context.theme.mainNavBarBackgroundColor,
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                        >
                          Add to &nbsp;
                          {this.context.theme.cartIcon == "Cart" ? (
                            <>
                              <BsCart2
                                size={"20px"}
                                style={{
                                  backgroundColor:
                                    this.context.theme
                                      .mainNavBarBackgroundColor,
                                  color: this.context.theme.mainNavBarTextColor,
                                }}
                              ></BsCart2>
                            </>
                          ) : (
                            <>
                              <BsBag
                                size={"20px"}
                                style={{
                                  backgroundColor:
                                    this.context.theme
                                      .mainNavBarBackgroundColor,
                                  color: this.context.theme.mainNavBarTextColor,
                                }}
                              ></BsBag>
                            </>
                          )}
                        </Button>
                      </>
                    ) : (
                      <>
                        {this.context.myCart.filter(
                          (f) => f.prod.id == this.props.prod.id
                        ).length == 1 ? (
                          <>
                            {/* <Button
                              variant="success"
                              type="submit"
                              // onClick={(e) =>
                              //   this.context.deleteItemOnMyCart(this.props.prod.id)
                              // }
                              style={{
                                backgroundColor: "#004225",
                                color: "yellow",
                              }}
                            >
                              ADDED
                            </Button> */}
                            <Stack direction="horizontal">
                              <div>
                                <h6 for="" class="col-form-label bold">
                                  Quantity : &nbsp;&nbsp;
                                </h6>
                              </div>
                              <div className="w-50 cartTable">
                                <IncDecCounterMobile
                                  intvalue={
                                    this.context.myCart.filter(
                                      (f) => f.prod.id == this.props.prod.id
                                    )[0].qty
                                  }
                                  valueChangeCallback={(e) => {
                                    this.context.UpdateQtyToCart(
                                      this.props.prod,
                                      e
                                    );
                                    this.context.forceUpdateCartView();
                                  }}
                                ></IncDecCounterMobile>
                              </div>
                            </Stack>
                          </>
                        ) : (
                          <>
                            <>
                              <Button
                                className="buttonRounded buttonwithshadow"
                                variant="primary"
                                type="submit"
                                // onClick={this.addToCartSubmit}
                                style={{
                                  backgroundColor:
                                    this.context.theme
                                      .mainNavBarBackgroundColor,
                                  color: this.context.theme.mainNavBarTextColor,
                                }}
                              >
                                Add to &nbsp;
                                {this.context.theme.cartIcon == "Cart" ? (
                                  <>
                                    <BsCart2
                                      size={"20px"}
                                      style={{
                                        backgroundColor:
                                          this.context.theme
                                            .mainNavBarBackgroundColor,
                                        color:
                                          this.context.theme
                                            .mainNavBarTextColor,
                                      }}
                                    ></BsCart2>
                                  </>
                                ) : (
                                  <>
                                    <BsBag
                                      size={"20px"}
                                      style={{
                                        backgroundColor:
                                          this.context.theme
                                            .mainNavBarBackgroundColor,
                                        color:
                                          this.context.theme
                                            .mainNavBarTextColor,
                                      }}
                                    ></BsBag>
                                  </>
                                )}
                              </Button>
                            </>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.prod.description,
                    }}
                  />
                </Col>
              </Row>

              {this.state.prod.relatedProductsSkuListByCsv ? (
                <>
                  <section class="bg-white">
                    <div class="container">
                      <div class="row py-5">
                        <div class="col-md-12 p-1">
                          <h2 class="semibold_font pt-2">Related Products</h2>
                          <div className="row justify-content-around py-2">
                            {this.state.prod.relatedProductsSkuListByCsv
                              .split(",")
                              .map((sku) => (
                                <>
                                  <div
                                    className="card mb-3 m-0 p-0 cardwithshadow "
                                    style={{
                                      width:
                                        this.context.theme
                                          .productCardWidthMobile -
                                        0.5 +
                                        "cm",
                                      backgroundColor:
                                        this.context.theme
                                          .productCardBackgroundColor,
                                      borderColor:
                                        this.context.theme
                                          .productCardBorderColor,
                                    }}
                                  >
                                    <div className="p-0 m-0">
                                      {this.context.prods.filter(
                                        (f) => f.sku == sku
                                      ).length == 1 ? (
                                        <>
                                          <ProductCardMobile
                                            parent="singleview"
                                            prod={
                                              this.context.prods.filter(
                                                (f) => f.sku == sku
                                              )[0]
                                            }
                                          ></ProductCardMobile>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <></>
              )}
            </Modal.Body>
            <Modal.Footer className="">
              <Form.Group>
                {/* {this.props.prod.productVariantType == 2 ? (
                  <>
                    <Button
                      variant="primary"
                      type="submit"
                      style={{
                        backgroundColor:
                          this.context.theme.mainNavBarBackgroundColor,
                        color: this.context.theme.mainNavBarTextColor,
                      }}
                    >
                      Add to &nbsp;
                      {this.context.theme.cartIcon == "Cart" ? (
                        <>
                          <BsCart2
                            size={"20px"}
                            style={{
                              backgroundColor:
                                this.context.theme.mainNavBarBackgroundColor,
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          ></BsCart2>
                        </>
                      ) : (
                        <>
                          <BsBag
                            size={"20px"}
                            style={{
                              backgroundColor:
                                this.context.theme.mainNavBarBackgroundColor,
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          ></BsBag>
                        </>
                      )}
                    </Button>
                  </>
                ) : (
                  <>
                    {this.context.myCart.filter(
                      (f) => f.prod.id == this.props.prod.id
                    ).length == 1 ? (
                      <>
                        <Button
                          variant="success"
                          type="submit"
                          // onClick={(e) =>
                          //   this.context.deleteItemOnMyCart(this.props.prod.id)
                          // }
                          style={{
                            backgroundColor: "#004225",
                            color: "yellow",
                          }}
                        >
                          ADDED
                        </Button>
                      </>
                    ) : (
                      <>
                        <>
                          <Button
                            variant="primary"
                            type="submit"
                            // onClick={this.addToCartSubmit}
                            style={{
                              backgroundColor:
                                this.context.theme.mainNavBarBackgroundColor,
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          >
                            Add to &nbsp;
                            {this.context.theme.cartIcon == "Cart" ? (
                              <>
                                <BsCart2
                                  size={"20px"}
                                  style={{
                                    backgroundColor:
                                      this.context.theme
                                        .mainNavBarBackgroundColor,
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                  }}
                                ></BsCart2>
                              </>
                            ) : (
                              <>
                                <BsBag
                                  size={"20px"}
                                  style={{
                                    backgroundColor:
                                      this.context.theme
                                        .mainNavBarBackgroundColor,
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                  }}
                                ></BsBag>
                              </>
                            )}
                          </Button>
                        </>
                      </>
                    )}
                  </>
                )} */}
              </Form.Group>
              {/* <Button variant="danger" onClick={this.props.onHide}>
                Close
              </Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
