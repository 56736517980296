import React, { Component, useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import Slider from "react-slick";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import { SampleNextArrow } from "./HomeAccessories";
import { SamplePrevArrow } from "./HomeAccessories";

import axios from "axios";

import {
  Accordion,
  Row,
  Col,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { ProductsList } from "./ProductsList";
import ProductCard from "./ProductCard";
import CartContext from "./CartContext";
import { LoadSpinner } from "./LoadSpinner";
import CartView from "./CartView";
export class Kiosk extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.context.SetPortalMode("Kiosk");
  };
  onMainCategoryClickEvent = (event) => {
    this.context.GetProductsByMainCategoryId(event.currentTarget.id);
  };

  onViewAllProductGroupClickEvent = (event) => {
    this.context.GetProductsByProductgroupId(event.currentTarget.id);
  };
  render() {
    const settingsProductGroup = {
      arrows: true,
      // dots: true,

      speed: 750,
      slidesToShow: Math.round(
        window.innerWidth /
          (this.context.theme.productGropupSlidesWidth == undefined
            ? 6 * 37
            : this.context.theme.productGropupSlidesWidth * 37),
        0
      ),
      infinite: false,
      // autoplay: true,
      slidesToScroll: 2,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "react__slick__slider__parent1",
    };
    return (
      <>
        <Row className="scroll-without-scrollbar">
          <Col xs="3">
            {this.context.mainCategories
              .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
              .map((maincategory) => (
                <>
                  <div
                    className="border text-start my-1"
                    style={{
                      backgroundColor:
                        this.context.theme.mainNavBarBackgroundColor ==
                        undefined
                          ? "white"
                          : this.context.theme.mainNavBarBackgroundColor,
                    }}
                  >
                    <Nav.Link
                      as={Link}
                      id={maincategory.id}
                      className="p-0 m-0"
                      to="/ProductsList"
                      onClick={(e) => {
                        this.onMainCategoryClickEvent(e);
                      }}
                      style={{
                        color: this.context.theme.categoryTextColor,
                        backgroundColor:
                          this.context.theme.productCardBackgroundColor,
                      }}
                    >
                      <Button
                        className="w-100 text-start"
                        size="lg"
                        style={{
                          backgroundColor:
                            this.context.theme.mainNavBarBackgroundColor ==
                            undefined
                              ? "white"
                              : this.context.theme.mainNavBarBackgroundColor,
                          color: this.context.theme.mainNavBarTextColor,
                          borderColor:
                            this.context.theme.mainNavBarTextColor == undefined
                              ? "white"
                              : this.context.theme.mainNavBarBackgroundColor,
                          fontSize:
                            this.context.theme.homepageTitlesFontSize + "px",
                        }}
                      >
                        <Row className="verticalaligncentertd">
                          <Col xs={5}>
                            <img
                              src={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/images/" +
                                maincategory.imgFileName +
                                this.context.store.storageSasToken
                              }
                              className={"CornersRoundedSmall "}
                              alt="..."
                              style={{
                                cursor: "pointer",
                                width: "2cm",
                                height: "2cm",
                              }}
                            ></img>
                          </Col>
                          <Col xs={7}>
                            <h3 class="bold_font">
                              <b>{maincategory.name}</b>
                            </h3>
                          </Col>
                        </Row>
                      </Button>
                    </Nav.Link>
                  </div>
                </>
              ))}
          </Col>
          <Col xs="9">
            <div
              className="fixedTableHightOrderQuque scroll-without-scrollbar"
              style={{
                minHeight: window.innerHeight - 10 + "px",
                maxHeight: window.innerHeight - 10 + "px",
                // display: "block",
                // overflowY: "auto",
                // scrollBehavior: "smooth",
                // msOverflowStyle: "none",
              }}
            >
              {this.context.prodsAll.length > 0
                ? this.context.productGroups
                    .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                    .map((pg) =>
                      pg.productGroupAndProductsMaps.length > 0 ? (
                        <>
                          <div className="p-5  m-2  border-0" style={{}}>
                            <Table className="m-0 p-0">
                              <tr className="">
                                <td class="text-start m-0 p-0 title-dsn">
                                  <h3
                                    className={
                                      "producttitlefontandsizeDesktop " +
                                      this.context.theme.homepageTitlesAlign
                                    }
                                    style={{
                                      fontSize:
                                        this.context.theme
                                          .homepageTitlesFontSize + "px",
                                      color:
                                        this.context.theme
                                          .homepageTitlesTextColor,
                                      textTransform:
                                        this.context.theme
                                          .homepageTitlesTextCase,
                                    }}
                                  >
                                    <h1 class="bold_font">{pg.name} </h1>
                                    {this.context.theme.hideProductCount ==
                                    "false" ? (
                                      <>
                                        <span
                                          style={{
                                            textTransform: "lowercase",
                                          }}
                                        >
                                          - [
                                          {
                                            this.context.productGroups.filter(
                                              (f) => f.name == pg.name
                                            )[0].productGroupAndProductsMaps
                                              .length
                                          }{" "}
                                          Products]
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    <h5>
                                      <Link
                                        id={pg.id}
                                        to="/ProductsList"
                                        className=" text-end p-2"
                                        style={{
                                          color:
                                            this.context.theme
                                              .homepageTitlesTextCase,
                                          textTransform: "none",
                                          fontSize: "16px",
                                        }}
                                        onClick={(e) => {
                                          this.onViewAllProductGroupClickEvent(
                                            e
                                          );
                                        }}
                                      >
                                        <b>View All</b>
                                      </Link>
                                    </h5>
                                  </h3>
                                </td>
                              </tr>
                            </Table>
                            <div className="py-1">
                              <Slider {...settingsProductGroup}>
                                {pg.productGroupAndProductsMaps != undefined
                                  ? pg.productGroupAndProductsMaps
                                      .sort((a, b) =>
                                        a.seqNo > b.seqNo ? 1 : -1
                                      )
                                      .map((map) =>
                                        this.context.prodsAll.find(
                                          (f) => f.id == map.productId
                                        ) != undefined ? (
                                          <div className="text-center ">
                                            <div
                                              className="card p-0 mx-2 mb-3 card-round text-center "
                                              style={{
                                                backgroundColor:
                                                  this.context.theme
                                                    .productCardBackgroundColor,
                                                borderColor:
                                                  this.context.theme
                                                    .productCardBorderColor,
                                              }}
                                            >
                                              <div
                                                className=" text-center "
                                                style={{
                                                  backgroundColor:
                                                    this.context.theme
                                                      .productCardBackgroundColor,
                                                }}
                                              >
                                                <ProductCard
                                                  prod={this.context.prodsAll.find(
                                                    (f) => f.id == map.productId
                                                  )}
                                                ></ProductCard>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      )
                                  : ""}
                              </Slider>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    )
                : ""}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
