import React, { Component, useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import { VscUnmute } from "react-icons/vsc";
import { MessageBox } from "./MessageBox";
import Slider from "react-slick";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";
import axios from "axios";
import { MySEO } from "./MySEO";
import { Blogs } from "./Blogs";
import { SlControlPlay } from "react-icons/sl";

import {
  Accordion,
  Row,
  Col,
  Table,
  NavLink,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { ProductsList } from "./ProductsList";
import ProductCard from "./ProductCard";
import CartContext from "./CartContext";
import { LoadSpinner } from "./LoadSpinner";

export class HomeBannerVideo extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  OnClickBannerVideo = (e) => {
    var vid = document.getElementById("bannervideo");
    vid.muted = !vid.muted;
  };

  render() {
    return (
      <>
        <div className="">
          <div className="bannervideo text-center img-holder">
            <video
              // preload="none"
              // height="200px"
              autoPlay
              muted
              id="bannervideo"
              loop
              width="100%"
              // controls
              className=""
              onClick={(e) => this.OnClickBannerVideo(e)}
            >
              <source
                src={
                  this.context.store.storageBlobUrl +
                  this.context.store.storageBlobContainerName +
                  "/images/" +
                  this.context.storeSettings.bannerVideoFileName +
                  this.context.store.storageSasToken
                }
                type="video/mp4"
              />
            </video>
            <h1
              className="textonimagecentered text-white w-100"
              style={{
                fontSize: "72px",
                letterSpacing: "15px",
                fontFamily: "Raleway Bold",
              }}
            >
              <b>{this.context.storeSettings.bannerVideoCenterMessage}</b>
            </h1>
            <h1
              className="textonimagebottom text-white w-100"
              style={{
                fontSize: "30px",
                letterSpacing: "5px",
                fontFamily: "cursive",
              }}
            >
              {this.context.storeSettings.bannerVideoBottomMessage}
            </h1>
            <Button
              className="link1"
              variant="primary"
              onClick={(e) => this.OnClickBannerVideo(e)}
            >
              {/* <SlControlPlay /> */}
              <VscUnmute></VscUnmute>
              {/* Play */}
            </Button>
          </div>
        </div>
        {/* <br /> */}
        {/* <br /> */}
      </>
    );
  }
}
export class HomeBannerVideoMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  OnClickBannerVideo = (e) => {
    var vid = document.getElementById("bannervideo");

    vid.muted = !vid.muted;
  };
  render() {
    return (
      <>
        <div className="bannervideomobile text-center img-holder" style={{}}>
          <video
            // preload="none"
            // height="200px"
            autoPlay
            muted
            loop
            width="100%"
            id="bannervideo"
            // controls
            className="p-0 m-0  "
            onClick={(e) => this.OnClickBannerVideo(e)}
          >
            <source
              src={
                this.context.store.storageBlobUrl +
                this.context.store.storageBlobContainerName +
                "/images/" +
                this.context.storeSettings.bannerVideoFileName +
                this.context.store.storageSasToken
              }
              type="video/mp4"
            />
          </video>
          <h1
            className="textonimagecentered text-white w-100"
            style={{
              fontSize: "14px",
              letterSpacing: "5px",
              fontFamily: "Raleway Bold",
            }}
          >
            <b>{this.context.storeSettings.bannerVideoCenterMessage}</b>
          </h1>
          <Button
            className="link1mobile"
            variant="primary"
            onClick={(e) => this.OnClickBannerVideo(e)}
          >
            {/* <SlControlPlay /> */}
            <VscUnmute></VscUnmute>
            {/* Play */}
          </Button>
        </div>
      </>
    );
  }
}

export class ConsumerMainCarousel extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({ index: selectedIndex });
  };

  GetRedirectPathForProductMenu = (menu) => {
    if (menu.productGroupId != null) return "/ProductListViewByProductGroup";
    if (menu.mainCategoryId != null) return "/ProductListViewByMainCategory";
    if (menu.subCategoryId != null) return "/ProductListViewBySubCategory";
  };

  OnMenuClick = (e, menu) => {
    this.context.UpdateReloadProductListView("true");
    if (menu.carouselType == 1) {
      //main category
      this.context.GetProductsByMainCategoryId(menu.mainCategoryId);
    } else if (menu.carouselType == 2) {
      //sub category

      this.context.GetProductsBySubCategoryId(menu.subCategoryId, null, null);
    } else if (menu.carouselType == 3) {
      this.context.GetProductsByProductgroupId(menu.productGroupId);
    }
  };

  render() {
    return (
      // <div className="Container-fluid mx-0 p-0 ">
      <div class="container-fluid carouselalign p-0 my-0">
        <div
          id="header-carousel"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active"></div>
            <Carousel
              // fade
              // slide={true}
              activeIndex={this.state.index}
              onSelect={this.handleSelect}
              indicators={false}
            >
              {this.context.mainCarousel
                .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                .map((carousel) => (
                  <Carousel.Item interval={1500}>
                    <Nav.Link
                      as={Link}
                      id={carousel.id}
                      to={this.GetRedirectPathForProductMenu(carousel)}
                      exact
                      onClick={(e) => {
                        this.OnMenuClick(e, carousel);
                      }}
                      className="m-0 p-0"
                    >
                      <img
                        className="d-block image-blurred-edge"
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          carousel.imgFileName +
                          this.context.store.storageSasToken
                        }
                        alt="First slide"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                          objectFit: "contain",
                          //height: theme.carouselImageHeight + "cm",
                          // height: window.innerHeight / 2,
                        }}
                      />
                    </Nav.Link>
                  </Carousel.Item>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

export function SampleNextArrow(props) {
  const { theme } = useContext(CartContext);
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: theme.sliderArrowBackgroundColor,
        height: "40px",
        width: "40px",
        color: "black",
        padding: "10px",
      }}
      onClick={onClick}
    ></div>
  );
}

export function SamplePrevArrow(props) {
  const { theme } = useContext(CartContext);
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",

        background: theme.sliderArrowBackgroundColor,

        height: "40px",
        width: "40px",
        color: "black",
        padding: "10px",
      }}
      onClick={onClick}
    />
  );
}

export class CertificatesSlider extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: false,
      clientCertificates: [],
    };
  }
  GetClientCertificates = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetClientCertificates/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            clientCertificates: data.sort((a, b) =>
              a.seqNo > b.seqNo ? 1 : -1
            ),
          });
        }
      })
      .catch((error) => {
        // alert("GetMainMenus:: " + error);
      });
  };
  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetClientCertificates();
    }
  };

  render() {
    var settings = "";
    if (window.innerWidth > 1200) {
      settings = {
        arrows: false,
        infinite: this.state.clientCertificates.length > 4,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        backgroundColor: "transparent",
        autoplaySpeed: 2000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent1",
      };
    } else {
      settings = {
        arrows: false,
        infinite: this.state.clientCertificates.length > 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        centerPadding: "100px",
        autoplaySpeed: 2000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent",
      };
    }
    return (
      <>
        <div
          className="py-0 "
          style={{
            fontSize: this.context.theme.homepageTitlesFontSize + "px",
          }}
        >
          <div
            className="px-2 my-5 m-2 title-dsn text-center "
            style={{
              fontSize: this.context.theme.homepageTitlesFontSize + "px",
              color: this.context.theme.homepageTitlesTextColor,
              textTransform: this.context.theme.homepageTitlesTextCase,
            }}
          >
            <h1 class="bold_font">CERTIFICATES</h1>
          </div>

          <div
            className="text-center px-5 border-0 py-0 border-secondary "
            style={{
              backgroundColor: this.context.theme.homepageBackgroundColor,
            }}
          >
            <Slider
              {...settings}
              style={{
                backgroundColor: this.context.theme.homepageBackgroundColor,
              }}
            >
              {this.state.clientCertificates
                .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                .map((c) => (
                  <>
                    <div
                      className="card verticalaligncentertd border-0 border-secondary CornersRounded px-2 m-2"
                      style={{
                        backgroundColor:
                          this.context.theme.homepageBackgroundColor,
                      }}
                    >
                      <a
                        href={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          c.imgFileName +
                          this.context.store.storageSasToken
                        }
                        target="_blank"
                      >
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            c.imgFileName +
                            this.context.store.storageSasToken
                          }
                          className=""
                          alt="..."
                          style={{
                            objectFit: "contain",
                            cursor: "pointer",
                            width: "5cm",
                            height: "5cm",
                          }}
                        ></img>{" "}
                      </a>
                    </div>
                  </>
                ))}
            </Slider>
          </div>
        </div>
      </>
    );
  }
}

export class HomeCountersDisplay extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: false,
    };
  }

  componentDidMount = () => {
    // if (this.state.firstLoad == false) {
    //   this.state.firstLoad = true;
    //   this.GetDisplayCounter();
    // }
    //this.GetDisplayCounter();
  };
  render() {
    if (this.context.displayCounter == "") return <></>;
    return (
      <>
        <Helmet>
          {/* <link rel="icon" type="image/x-icon" href="dist/images/fav.ico" /> */}
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
        </Helmet>
        {window.innerWidth > 1200 ? (
          <>
            <section class="count_sec">
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <div class="count-grp text-center">
                      <h3 class="bold_font">
                        <span class="count">
                          {this.context.displayCounter.maxValue1}
                        </span>
                        {this.context.displayCounter.endingLetter1}{" "}
                      </h3>
                      <h5 class="mb-0">{this.context.displayCounter.name1}</h5>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="count-grp text-center">
                      <h3 class="bold_font">
                        <span class="count">
                          {this.context.displayCounter.maxValue2}
                        </span>
                        {this.context.displayCounter.endingLetter2}{" "}
                      </h3>
                      <h5 class="mb-0">{this.context.displayCounter.name2}</h5>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="count-grp text-center">
                      <h3 class="bold_font">
                        <span class="count">
                          {this.context.displayCounter.maxValue3}
                        </span>
                        {this.context.displayCounter.endingLetter3}{" "}
                      </h3>
                      <h5 class="mb-0">{this.context.displayCounter.name3}</h5>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="count-grp text-center">
                      <h3 class="bold_font">
                        <span class="count">
                          {this.context.displayCounter.maxValue4}
                        </span>
                        {this.context.displayCounter.endingLetter4}{" "}
                      </h3>
                      <h5 class="mb-0">{this.context.displayCounter.name4}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <section class="">
              <div class="container">
                <Row>
                  <Col>
                    <div class="count-grp-mobile text-center">
                      <h3 class="bold_font">
                        <span class="count">
                          {this.context.displayCounter.maxValue1}
                        </span>
                        {this.context.displayCounter.endingLetter1}{" "}
                      </h3>
                      <h5 class="mb-0">{this.context.displayCounter.name1}</h5>
                    </div>
                  </Col>
                  <Col>
                    <div class="count-grp-mobile text-center">
                      <h3 class="bold_font">
                        <span class="count">
                          {this.context.displayCounter.maxValue2}
                        </span>
                        {this.context.displayCounter.endingLetter2}{" "}
                      </h3>
                      <h5 class="mb-0">{this.context.displayCounter.name2}</h5>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <div class="count-grp-mobile text-center">
                      <h3 class="bold_font">
                        <span class="count">
                          {this.context.displayCounter.maxValue3}
                        </span>
                        {this.context.displayCounter.endingLetter3}{" "}
                      </h3>
                      <h5 class="mb-0">{this.context.displayCounter.name3}</h5>
                    </div>
                  </Col>
                  <Col>
                    <div class="count-grp-mobile text-center">
                      <h3 class="bold_font">
                        <span class="count">
                          {this.context.displayCounter.maxValue4}
                        </span>
                        {this.context.displayCounter.endingLetter4}{" "}
                      </h3>
                      <h5 class="mb-0">{this.context.displayCounter.name4}</h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </section>
          </>
        )}
      </>
    );
  }
}

export class ClientsSlider extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      clientLogos: [],
      firstLoad: false,
    };
  }
  GetClientLogos = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetClientLogos/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            clientLogos: data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)),
          });
        }
      })
      .catch((error) => {
        // alert("GetMainMenus:: " + error);
      });
  };
  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetClientLogos();
    }
  };
  render() {
    var settings = "";
    if (window.innerWidth > 1200) {
      settings = {
        arrows: false,
        infinite: this.state.clientLogos.length > 6,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 1000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent1",
      };
    } else {
      settings = {
        arrows: false,
        infinite: this.state.clientLogos.length > 2,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 1000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent",
      };
    }
    return (
      <div className="text-center px-5 py-5 my-5 border-0">
        <div
          className="px-2 my-5 m-2 title-dsn text-center "
          style={{
            fontSize: this.context.theme.homepageTitlesFontSize + "px",
            color: this.context.theme.homepageTitlesTextColor,
            textTransform: this.context.theme.homepageTitlesTextCase,
          }}
        >
          <h1 class="bold_font">OUR CLIENTS</h1>
        </div>

        <Slider {...settings}>
          {this.state.clientLogos
            .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
            .map((logo) => (
              <>
                <div className="verticalaligncentertd ">
                  <img
                    src={
                      this.context.store.storageBlobUrl +
                      this.context.store.storageBlobContainerName +
                      "/images/" +
                      logo.imgFileName +
                      this.context.store.storageSasToken
                    }
                    className=""
                    alt="..."
                    style={{
                      objectFit: "contain",
                      // cursor: "pointer",
                      width: "4cm",
                      height: "3cm",
                    }}
                  ></img>{" "}
                </div>
              </>
            ))}
        </Slider>
        {/* <hr /> */}
      </div>
    );
  }
}

export class Testimonial extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: false,
      clientTestimonials: [],
    };
  }
  GetClientTestimonials = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetClientTestimonials/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            clientTestimonials: data.sort((a, b) =>
              a.seqNo > b.seqNo ? 1 : -1
            ),
          });
        }
      })
      .catch((error) => {
        // alert("GetMainMenus:: " + error);
      });
  };
  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetClientTestimonials();
    }
  };
  render() {
    var settings = "";
    if (window.innerWidth > 1200) {
      settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "80px",
        slidesToShow: 3,
        speed: 500,
        cssease: "linear",
        variablewidth: true,
        variableheight: true,

        arrows: true,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent1",
      };
    } else {
      settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 100,
        // variablewidth: true,
        // variableheight: true,
        arrows: true,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent",
      };
    }
    return (
      <>
        {window.innerWidth > 1200 ? (
          <>
            <div
              className="text-center "
              style={
                {
                  // backgroundColor: this.context.theme.menuNavBarbackgroundColor,
                  // color: this.context.theme.mainNavBarTextColor,
                }
              }
            >
              <div
                className="px-2 my-5 m-2 title-dsn"
                style={{
                  fontSize: this.context.theme.homepageTitlesFontSize + "px",
                  color: this.context.theme.homepageTitlesTextColor,
                  textTransform: this.context.theme.homepageTitlesTextCase,
                }}
              >
                <h1 class="bold_font">TESTIMONIAL</h1>
              </div>

              <Slider {...settings}>
                {this.state.clientTestimonials
                  .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                  .map((c) => (
                    <>
                      <div
                        className="p-3 m-3  CornersHalfRounded border"
                        style={{
                          backgroundColor:
                            this.context.theme.homepageBackgroundColor,
                          color: this.context.theme.categoryTextColor,
                          height: "10cm",
                        }}
                      >
                        <table className="  w-100   justify-content-around ">
                          <tbody>
                            <tr>
                              <td sm={12} className="text-center ">
                                <div
                                  className="w-100 text-center "
                                  style={{ fontSize: "18px" }}
                                >
                                  {c.imgFileName ? (
                                    <>
                                      <a
                                        href={
                                          this.context.store.storageBlobUrl +
                                          this.context.store
                                            .storageBlobContainerName +
                                          "/images/" +
                                          c.imgFileName +
                                          this.context.store.storageSasToken
                                        }
                                        target="_blank"
                                      >
                                        <img
                                          src={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            c.imgFileName +
                                            this.context.store.storageSasToken
                                          }
                                          className="rounded-circle border border-dark"
                                          alt="..."
                                          style={{
                                            objectFit: "contain",
                                            // cursor: "pointer",
                                            width: "2cm",
                                            height: "2cm",
                                          }}
                                        ></img>{" "}
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      {/* <img
                                    src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                                    className="rounded-circle border border-dark bg-white text-center"
                                    alt="..."
                                    style={{
                                      objectFit: "contain",
                                      cursor: "pointer",
                                      width: "2cm",
                                      height: "2cm",
                                    }}
                                  ></img>{" "} */}
                                    </>
                                  )}

                                  <div
                                    className="w-100 text-center  my-3"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <b>{c.customerName}</b>
                                  </div>
                                  <div
                                    className="w-100 text-center  my-3 "
                                    style={{ fontSize: "24px" }}
                                  >
                                    {c.totalStars == 1 ? (
                                      <>&#9733;</>
                                    ) : c.totalStars == 2 ? (
                                      <>&#9733;&#9733;</>
                                    ) : c.totalStars == 3 ? (
                                      <>&#9733;&#9733;&#9733;</>
                                    ) : c.totalStars == 4 ? (
                                      <>&#9733;&#9733;&#9733;&#9733;</>
                                    ) : c.totalStars == 5 ? (
                                      <>&#9733;&#9733;&#9733;&#9733;&#9733;</>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="mx-3">{c.feedback}</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ))}
              </Slider>
              <br />
            </div>
          </>
        ) : (
          <>
            <div className="text-center p-2  " style={{}}>
              <div
                className="px-0 my-5 m-2 title-dsn"
                style={{
                  fontSize: this.context.theme.homepageTitlesFontSize + "px",
                  color: this.context.theme.homepageTitlesTextColor,
                  textTransform: this.context.theme.homepageTitlesTextCase,
                }}
              >
                <h1 class="bold_font">TESTIMONIAL</h1>

                <Slider {...settings}>
                  {this.state.clientTestimonials
                    .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                    .map((c) => (
                      <>
                        <div
                          className="p-3 m-3  CornersHalfRounded border  "
                          style={{
                            backgroundColor:
                              this.context.theme.homepageBackgroundColor,
                            color: this.context.theme.categoryTextColor,
                          }}
                        >
                          <table className="  w-100  justify-content-around ">
                            <tbody>
                              <tr>
                                <td sm={12} className="text-center ">
                                  <div
                                    className="w-100 text-center "
                                    style={{ fontSize: "18px" }}
                                  >
                                    {c.imgFileName ? (
                                      <>
                                        <a
                                          href={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            c.imgFileName +
                                            this.context.store.storageSasToken
                                          }
                                          target="_blank"
                                        >
                                          <img
                                            src={
                                              this.context.store
                                                .storageBlobUrl +
                                              this.context.store
                                                .storageBlobContainerName +
                                              "/images/" +
                                              c.imgFileName +
                                              this.context.store.storageSasToken
                                            }
                                            className="rounded-circle border border-dark"
                                            alt="..."
                                            style={{
                                              objectFit: "contain",
                                              // cursor: "pointer",
                                              width: "2cm",
                                              height: "2cm",
                                            }}
                                          ></img>{" "}
                                        </a>
                                      </>
                                    ) : (
                                      <>
                                        {/* <img
                                    src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                                    className="rounded-circle border border-dark bg-white text-center"
                                    alt="..."
                                    style={{
                                      objectFit: "contain",
                                      cursor: "pointer",
                                      width: "2cm",
                                      height: "2cm",
                                    }}
                                  ></img>{" "} */}
                                      </>
                                    )}

                                    <div
                                      className="w-100 text-center  my-3"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <b>{c.customerName}</b>
                                    </div>
                                    <div
                                      className="w-100 text-center  my-3 "
                                      style={{ fontSize: "24px" }}
                                    >
                                      {c.totalStars == 1 ? (
                                        <>&#9733;</>
                                      ) : c.totalStars == 2 ? (
                                        <>&#9733;&#9733;</>
                                      ) : c.totalStars == 3 ? (
                                        <>&#9733;&#9733;&#9733;</>
                                      ) : c.totalStars == 4 ? (
                                        <>&#9733;&#9733;&#9733;&#9733;</>
                                      ) : c.totalStars == 5 ? (
                                        <>&#9733;&#9733;&#9733;&#9733;&#9733;</>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="mx-3"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {c.feedback}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    ))}
                </Slider>
              </div>
              <br />
            </div>
          </>
        )}
      </>
    );
  }
}

export class Vlogs extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: false,
      Vlogs: [],
    };
  }
  GetVlogs = () => {
    fetch(
      process.env.REACT_APP_API + "Consumers/GetVlogs/" + this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            Vlogs: data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)),
          });
        }
      })
      .catch((error) => {
        // alert("GetMainMenus:: " + error);
      });
  };
  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetVlogs();
    }
  };
  render() {
    var settings = "";
    if (window.innerWidth > 1200) {
      settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent1",
      };
    } else {
      settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        speed: 100,
        autoplay: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "react__slick__slider__parent",
      };
    }
    return (
      <>
        {window.innerWidth > 1200 ? (
          <>
            <div
              class="bold_font"
              className=" text-center border-0  "
              style={{
                fontSize: this.context.theme.homepageTitlesFontSize + "px",
                color: this.context.theme.homepageTitlesTextColor,
                textTransform: this.context.theme.homepageTitlesTextCase,
              }}
            >
              <h1 class="bold_font">VLOGs</h1>

              <Slider {...settings}>
                {this.state.Vlogs.sort((a, b) =>
                  a.seqNo > b.seqNo ? 1 : -1
                ).map((c) => (
                  <>
                    <div className="p-2 m-2" width={window.innerWidth / 4}>
                      {c.fileName ? (
                        <>
                          <video
                            preload="none"
                            height="200px"
                            // className="w-100"
                            poster={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              c.posterName +
                              this.context.store.storageSasToken
                            }
                            controls
                            // style={{
                            //   cursor: "pointer",
                            // }}
                          >
                            <source
                              src={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/images/" +
                                c.fileName +
                                this.context.store.storageSasToken
                              }
                              // type="video/mp4"
                            />
                          </video>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="mx-1" style={{ fontSize: "14px" }}>
                      {c.description}
                    </div>
                  </>
                ))}
              </Slider>
            </div>
          </>
        ) : (
          <>
            <div className="text-center p-2 text-dark ">
              <div
                className="px-0 my-5 m-0 title-dsn "
                style={{
                  fontSize: this.context.theme.homepageTitlesFontSize + "px",
                  color: this.context.theme.homepageTitlesTextColor,
                  textTransform: this.context.theme.homepageTitlesTextCase,
                }}
              >
                <h1 class="bold_font">Vlogs</h1>

                <Slider {...settings}>
                  {this.state.Vlogs.sort((a, b) =>
                    a.seqNo > b.seqNo ? 1 : -1
                  ).map((c) => (
                    <>
                      <div
                        className="p-1 m-0  "
                        style={{
                          // backgroundColor:
                          //   this.context.theme.mainNavBarBackgroundColor,
                          color: this.context.theme.mainNavBarTextColor,
                        }}
                      >
                        <table className="  w-100  justify-content-around ">
                          <tbody>
                            <tr>
                              <td className="text-center ">
                                <div
                                  className="w-100 text-center "
                                  style={{ fontSize: "12px" }}
                                >
                                  {c.fileName ? (
                                    <>
                                      <video
                                        preload="none"
                                        // width="100%"
                                        height="150px"
                                        poster={
                                          this.context.store.storageBlobUrl +
                                          this.context.store
                                            .storageBlobContainerName +
                                          "/images/" +
                                          c.posterName +
                                          this.context.store.storageSasToken
                                        }
                                        controls
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <source
                                          src={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/images/" +
                                            c.fileName +
                                            this.context.store.storageSasToken
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div
                                  className="mx-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  {c.description}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ))}
                </Slider>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export class ProductGroupCarousel extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({ index: selectedIndex });
  };

  GetRedirectPathForProductMenu = (menu) => {
    if (menu.productGroupId != null) return "/ProductListViewByProductGroup";
    if (menu.mainCategoryId != null) return "/ProductListViewByMainCategory";
    if (menu.subCategoryId != null) return "/ProductListViewBySubCategory";
  };

  OnMenuClick = (e, menu) => {
    this.context.UpdateReloadProductListView("true");
    if (menu.carouselType == 1) {
      //main category
      this.context.GetProductsByMainCategoryId(menu.mainCategoryId);
    } else if (menu.carouselType == 2) {
      //sub category

      this.context.GetProductsBySubCategoryId(menu.subCategoryId, null, null);
    } else if (menu.carouselType == 3) {
      this.context.GetProductsByProductgroupId(menu.productGroupId);
    }
  };
  OnClickShopNow = (prod) => {
    this.context.StoreShopnowProduct(prod);
  };
  onViewAllProductGroupClickEvent = (event) => {
    this.context.GetProductsByProductgroupId(event.currentTarget.id);
  };
  IsVideo(filename) {
    if (filename) {
      var f = filename.split(".").pop();
      if (f == "mp4") return true;
    }
    return false;
  }

  render() {
    if (
      this.context.productGroups.length <= 0 ||
      this.context.prodsAll.length <= 0
    )
      return <></>;
    return (
      <>
        <div
          class="container-fluid carouselalign p-0 TopCornerRounded"
          style={{
            color: this.context.theme.mainNavBarTextColor,
            backgroundColor: this.context.theme.mainNavBarBackgroundColor,
          }}
        >
          <div className="text-center TopCornerRounded">
            <h1 className="title-dsn producttitlefontandsizeDesktop ">
              <b>{this.props.productGroup.name}</b>{" "}
            </h1>
            <Button
              as={Link}
              className="my-2 "
              id={this.props.productGroup.id}
              to="/ProductsList"
              size="sm"
              style={{
                backgroundColor: this.context.theme.mainNavBarBackgroundColor,
                color: this.context.theme.mainNavBarTextColor,
                borderColor: this.context.theme.mainNavBarTextColor,
              }}
              onClick={(e) => {
                this.onViewAllProductGroupClickEvent(e);
              }}
            >
              <b>View All</b>
            </Button>
          </div>
          <div
            id="header-carousel"
            class="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active"></div>
              <Carousel
                // fade
                // data-bs-theme="dark"
                activeIndex={this.state.index}
                onSelect={this.handleSelect}
              >
                {this.props.productGroup.productGroupAndProductsMaps.map((m) =>
                  this.context.prodsAll.find((f) => f.id == m.productId) !=
                  undefined ? (
                    <Carousel.Item interval={2500}>
                      {this.IsVideo(
                        this.context.prodsAll.find((f) => f.id == m.productId)
                          .imgFileName
                      ) == true ? (
                        <>
                          <video
                            preload="none"
                            poster={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.context.prodsAll.find(
                                (f) => f.id == m.productId
                              ).posterName +
                              this.context.store.storageSasToken
                            }
                            // controls="false"
                            onClick={(e) => {
                              if (
                                this.props.parent != "singleview" &&
                                this.props.parent == undefined
                              )
                                this.OpenSingleView(e);
                            }}
                            style={{
                              cursor: "pointer",

                              width: "100%",
                              objectFit: "contain",
                            }}
                          >
                            <source
                              src={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/images/" +
                                this.context.prodsAll.find(
                                  (f) => f.id == m.productId
                                ).imgFileName +
                                this.context.store.storageSasToken
                              }
                              // type="video/mp4"
                            />
                          </video>
                        </>
                      ) : (
                        <>
                          <img
                            className="d-block img-hover-mover"
                            src={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.context.prodsAll.find(
                                (f) => f.id == m.productId
                              ).imgFileName +
                              this.context.store.storageSasToken
                            }
                            alt="First slide"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                              objectFit: "contain",
                            }}
                          />
                        </>
                      )}
                      <Carousel.Caption>
                        <h4>
                          <b>
                            {
                              this.context.prodsAll.find(
                                (f) => f.id == m.productId
                              ).name
                            }
                          </b>
                        </h4>
                        <h5>
                          <b>
                            {Number(
                              this.context.prodsAll.find(
                                (f) => f.id == m.productId
                              ).mrp -
                                (this.context.prodsAll.find(
                                  (f) => f.id == m.productId
                                ).mrp *
                                  this.context.prodsAll.find(
                                    (f) => f.id == m.productId
                                  ).discount) /
                                  100
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </b>
                          &nbsp;&nbsp; ({" "}
                          {
                            this.context.prodsAll.find(
                              (f) => f.id == m.productId
                            ).discount
                          }
                          % Discount )
                        </h5>

                        <Button
                          as={Link}
                          size="lg"
                          style={{
                            backgroundColor:
                              this.context.theme.mainNavBarBackgroundColor,
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                          to="/ProductSingleProductViewWrapper"
                          onClick={(e) => {
                            this.OnClickShopNow(
                              this.context.prodsAll.find(
                                (f) => f.id == m.productId
                              )
                            );
                          }}
                        >
                          <b>View</b>
                        </Button>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ) : (
                    ""
                  )
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}
