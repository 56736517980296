import React, { Component, useState } from "react";
import { useContext } from "react";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { Carousel, Nav, Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ProductCardMobile from "./ProductCardMobile";
import CartContext from "./CartContext";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { MySEO } from "./MySEO";
import { Blogs } from "./Blogs";
import { HomeCountersDisplay } from "./HomeAccessories";
import { Testimonial } from "./HomeAccessories";
import { CertificatesSlider } from "./HomeAccessories";
import { ClientsSlider } from "./HomeAccessories";
import { Vlogs } from "./HomeAccessories";
import { HomeBannerVideoMobile } from "./HomeAccessories";
import { LanguageSelection } from "./LanguageSelection";

export class HomeMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: true,
      firsload: false,
      showEnterpriseStoreModel: "false",
      reload: false,
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  fakeRequest = (timeout) => {
    return new Promise((resolve) =>
      setTimeout(
        () => resolve(),
        //this.context.storeSettings.homePageLoadingTimeInSecond
        timeout
      )
    );
  };

  hideEnterpriseStoreModel = () => {
    this.setState({ showEnterpriseStoreModel: false });
  };

  GetMyStoreDetails = (pinCode) => {
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetMyStoreDetailsByPincodeForEnterprise/" +
        this.context.storeId +
        "/" +
        pinCode
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert(
            "This Application is not active yet. Please contact your Vendor!"
          );
        } else {
          if (new Date(data.storeEnd) > new Date() == false) {
            alert("Store is Inactive. Please contact your Vendor!");
            return;
          }
          this.context.SetEnterpriseLocation(pinCode);
          this.context.setStoreId(data.id);
          this.context.SetStore(data);
          this.setState({ storeId: data.id }, () => {
            this.setState({ showEnterpriseStoreModel: false });
            this.context.SetDeliveryPinCode(pinCode);
            this.context.GetStoreSettings();
            this.context.GetDisplayCounter();
            this.context.GetVisitorsCount();
            this.context.GetMainCarousel(1); //1-desktop 2-Mobile
            this.context.GetOrderStatusTypes();
            this.context.getMainCategories();
            this.context.getSubCategories();
            this.context.getBrands();
            this.context.GetMainMenus();
            this.context.GetTop5Ads();
            this.context.GetAllProducts();
            this.context.GetProductGroups();
            this.context.getShippingDetails();
            this.context.getTaxDetails();
            this.context.GetProductDataType();
            this.context.StoreMyIpAddress();
          });
        }
      })
      .catch((error) => {
        alert("We are sorry as We don't have delivery to this Location!");
      });
  };

  componentDidUpdate = () => {
    if (this.context.refreshIncDecControl == true) {
      this.context.SetRefreshIncDecControl(false);
      this.setState({ reload: true }, () => {
        this.setState({ reload: false });
      });
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.state.firsload == false) {
      this.state.firsload = true;
      if (this.context.prodsAll.length > 0) {
        this.setState({ loadSpinnerOpen: false }); // showing the app
        this.context.SetHomePageLoadedStatus();
        return;
      }
      this.fakeRequest(100).then(() => {
        if (
          this.context.storeSettings != "" &&
          this.context.prodsAll.length == 0
        ) {
          this.fakeRequest(
            this.context.storeSettings.homePageLoadingTimeInSecond
          ).then(() => {
            this.setState({ loadSpinnerOpen: false }); // showing the app
            this.context.SetHomePageLoadedStatus();
          });
        } else {
          this.setState({ loadSpinnerOpen: false }); // showing the app
          this.context.SetHomePageLoadedStatus();
        }
      });
      this.fakeRequest(2000).then(() => {
        this.LoadEnterprise();
      });
    }
  }
  LoadEnterprise = () => {
    if (
      this.context.store &&
      this.context.store.storeTypeId == this.context.storeType.Enterprise &&
      this.context.deliveryPincode == ""
    ) {
      this.setState({ showEnterpriseStoreModel: true });
    }
  };

  ShopNow = (e) => {
    this.setState({ showEnterpriseStoreModel: true });
  };

  // onSubCategoryClickEvent = (event) => {
  //   this.context.GetProductsBySubCategoryId(event.currentTarget.id, null, null);
  // };

  // onMainCategoryClickEvent = (event) => {
  //   this.context.GetProductsByMainCategoryId(event.currentTarget.id);
  // };

  // onBrandClickEvent = (event) => {
  //   this.context.GetProductsByBrandId(event.currentTarget.id);
  // };

  // onViewAllProductGroupClickEvent = (event) => {
  //   this.context.GetProductsByProductgroupId(event.currentTarget.id);
  // };
  // OnAdClick = (e, ad) => {
  //   this.context.UpdateReloadProductListView("true");
  //   if (ad.adType == 1) {
  //     //main category
  //     this.context.GetProductsByMainCategoryId(ad.mainCategoryId);
  //   } else if (ad.adType == 2) {
  //     //sub category

  //     this.context.GetProductsBySubCategoryId(ad.subCategoryId, null, null);
  //   } else if (ad.adType == 3) {
  //     this.context.GetProductsByProductgroupId(ad.productGroupId);
  //   }
  // };
  // GetRedirectPathForProductMenu(menu) {
  //   if (menu.productGroupId != null) return "/ProductsListMobile";
  //   if (menu.mainCategoryId != null) return "/ProductsListMobile";
  //   if (menu.subCategoryId != null) return "/ProductsListMobile";
  // }

  render() {
    if (this.context.storeSettings == "" || this.context.theme == "") return;

    // if (
    //   this.state.loadSpinnerOpen == true ||
    //   this.context.storeId == "" ||
    //   this.context.mainCategories.length == 0 ||
    //   this.context.subCategories.length == 0
    // ) {
    //   return (
    //     <>
    //       {this.state.loadSpinnerOpen === true ? (
    //         <LoadSpinner open="true"></LoadSpinner>
    //       ) : (
    //         ""
    //       )}
    //     </>
    //   );
    // }

    if (
      (this.context.storeId == "" ||
        this.context.mainCategories.length == 0 ||
        this.context.subCategories.length == 0) &&
      this.context.store.storeTypeId != this.context.storeType.Enterprise
    ) {
      return <></>;
    }

    return (
      <div
        className=""
        style={{
          backgroundColor: this.context.theme.homepageBackgroundColor,
          paddingTop:
            this.context.store.storeTypeId == this.context.storeType.Enterprise
              ? "0px"
              : "60px",
        }}
      >
        <Helmet>
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/style.css" rel="stylesheet" />
          <link href="dist/css/responsive.css" rel="stylesheet" />
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          <script src="dist/js/custom.js"></script>
        </Helmet>
        {this.context.storeSettings.multiCurrencySupport == true &&
        this.context.islanguageSelected == false ? (
          <>
            <LanguageSelection></LanguageSelection>
          </>
        ) : (
          <></>
        )}
        {this.state.showEnterpriseStoreModel == false &&
        this.context.store.storeTypeId == this.context.storeType.Enterprise ? (
          <>
            <Button
              className="shop-now CornersRounded border-white"
              style={{
                fontSize: "24px",
                backgroundColor: "yellowgreen",
              }}
              onClick={(e) => this.ShopNow(e)}
            >
              &nbsp;&nbsp;Shop Now...&nbsp;&nbsp;
            </Button>
          </>
        ) : (
          <></>
        )}
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {/* {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )} */}
        <MySEO></MySEO>
        {this.context.portalMode == "CustomerPortal" ? (
          <>
            {this.context.theme.hideCarouselMobile == false ? (
              <>
                {this.context.storeSettings.bannerVideoFileName ? (
                  <>
                    <div
                    // style={{
                    //   backgroundColor:
                    //     this.context.theme.mainNavBarBackgroundColor,
                    // }}
                    >
                      <HomeBannerVideoMobile></HomeBannerVideoMobile>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {window.location.hostname == "lifestyleaqua.com" ||
                window.location.hostname == "www.lifestyleaqua.com" ? (
                  <>
                    <Row className="">
                      <div className="border-0 m-0 p-0 text-center " style={{}}>
                        <Nav.Link
                          as={Link}
                          to="/LifeStyleAquaAuthenticator"
                          className="logohover my-1"
                          // style={{
                          //   color: this.context.theme.mainNavBarTextColor,
                          // }}
                        >
                          <i>
                            <u>Arowana Authenticator</u>
                          </i>
                        </Nav.Link>
                      </div>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                {this.context.storeSettings.hideDisplayCounters === false &&
                this.context.storeSettings.bannerVideoFileName ? (
                  <>
                    <br />
                    <HomeCountersDisplay></HomeCountersDisplay>
                    <br />
                  </>
                ) : (
                  <></>
                )}

                <ConsumerMainCarouselMobile></ConsumerMainCarouselMobile>
                <br></br>
              </>
            ) : (
              ""
            )}
            {this.context.storeSettings.hideDisplayCounters === false &&
            (this.context.storeSettings.bannerVideoFileName == undefined ||
              this.context.storeSettings.bannerVideoFileName == "") ? (
              <>
                <HomeCountersDisplay></HomeCountersDisplay>
                <br />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {this.state.showEnterpriseStoreModel == true ? (
          <>
            <EnterpriseStoreModelMobile
              onHide={this.hideEnterpriseStoreModel}
              GetMyStoreDetails={this.GetMyStoreDetails}
            ></EnterpriseStoreModelMobile>
          </>
        ) : (
          <>
            <div
              className="Container-fluid mx-2 px-0"
              style={{
                backgroundColor: this.context.theme.homepageBackgroundColor,
              }}
            >
              {this.context.store.businessTypeId == 20 ? (
                <ShowBrandsMobile></ShowBrandsMobile>
              ) : (
                <></>
              )}

              <ShowMainCategoryMobile></ShowMainCategoryMobile>

              {this.context.portalMode == "CustomerPortal" ? (
                <>
                  <Top5AdsMobile></Top5AdsMobile>
                  <ShowSubCategoryMobile></ShowSubCategoryMobile>
                </>
              ) : (
                <></>
              )}

              <ShowProductGroupsMobile
                reload={this.state.reload}
              ></ShowProductGroupsMobile>
              {this.context.store.businessTypeId == 20 ? (
                <></>
              ) : (
                <>
                  <ShowBrandsMobile></ShowBrandsMobile>
                </>
              )}
            </div>
            {this.context.portalMode == "CustomerPortal" ? (
              <>
                {this.context.storeSettings.hideTestimonials === false ? (
                  <>
                    <Testimonial></Testimonial>
                  </>
                ) : (
                  <></>
                )}

                {this.context.storeSettings.hideCertificates === false ? (
                  <>
                    <CertificatesSlider></CertificatesSlider>
                  </>
                ) : (
                  <></>
                )}
                {this.context.storeSettings.hideClientLogos === false ? (
                  <>
                    <ClientsSlider></ClientsSlider>
                  </>
                ) : (
                  <></>
                )}
                {this.context.storeSettings.hideVlogs === false ? (
                  <>
                    <Vlogs></Vlogs>
                  </>
                ) : (
                  <></>
                )}
                {this.context.storeSettings.hideBlogs === false ? (
                  <>
                    <Blogs></Blogs>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    );
  }
}

export class EnterpriseStoreModelMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      storeId: "",
      pinCode: "",
    };
  }

  OnChangePincode = (event) => {
    this.setState({ pinCode: event.target.value });
  };
  componentDidMount = () => {
    var ctrl = document.getElementById("pincode");
    if (ctrl) ctrl.focus();
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    this.props.GetMyStoreDetails(this.state.pinCode);
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          // onHide={this.props.onHide}
          dialogClassName="ProductModal"
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-white text-dark">
              <h3>Select Delivery Location</h3>
            </Modal.Header>

            <Modal.Body
              className=""
              style={{
                fontSize: "16px",
              }}
            >
              <Row className="verticalaligncentertd">
                {/* <Col xs={6}>Enter Pincode:</Col>
                <Col xs={6}>
                  <Form.Control
                    id="pincode"
                    type="number"
                    placeholder=""
                    className=" "
                    required
                    minLength={6}
                    maxLength={6}
                    value={this.state.pinCode}
                    onChange={(e) => this.OnChangePincode(e)}
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "20px",
                    }}
                  />
                </Col> */}
                <Col>
                  <Form.Select
                    onChange={(e) => this.OnChangePincode(e)}
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "20px",
                    }}
                  >
                    <option value=""></option>
                    {this.context.store.enterpriseStores.map((m) => (
                      <option value={m.pincodes}>{m.location}</option>
                    ))}
                    <option value="">Other Location</option>
                  </Form.Select>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className=""
                variant="primary"
                type="submit"
                size="lg"
                // onClick={(e) => this.handleSubmit(e)}
              >
                <b>&nbsp;&nbsp;Proceed...&nbsp;&nbsp;</b>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export class ConsumerMainCarouselMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({ index: selectedIndex });
  };

  GetRedirectPathForProductMenu = (menu) => {
    if (menu.productGroupId != null)
      return "/ProductListViewByProductGroupMobile";
    if (menu.mainCategoryId != null)
      return "/ProductListViewByMainCategoryMobile";
    if (menu.subCategoryId != null)
      return "/ProductListViewBySubCategoryMobile";
  };

  OnMenuClick = (e, menu) => {
    this.context.UpdateReloadProductListView("true");
    if (menu.carouselType == 1) {
      //main category
      this.context.GetProductsByMainCategoryId(menu.mainCategoryId);
    } else if (menu.carouselType == 2) {
      //sub category

      this.context.GetProductsBySubCategoryId(menu.subCategoryId, null, null);
    } else if (menu.carouselType == 3) {
      this.context.GetProductsByProductgroupId(menu.productGroupId);
    }
  };

  render() {
    return (
      <>
        <div class=" py-2 m-0 border-0 border-dark ">
          <div
            id="header-carousel"
            class="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner p-0 m-0">
              <Carousel
                // fade
                activeIndex={this.state.index}
                onSelect={this.handleSelect}
                indicators={false}
              >
                {this.context.mainCarousel
                  .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                  .map((carousel) => (
                    <Carousel.Item interval={1500}>
                      <Nav.Link
                        as={Link}
                        id={carousel.id}
                        to={this.GetRedirectPathForProductMenu(carousel)}
                        exact
                        onClick={(e) => {
                          this.OnMenuClick(e, carousel);
                        }}
                        className="m-0 p-0"
                      >
                        <img
                          className="d-block "
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            carousel.imgFileName +
                            this.context.store.storageSasToken
                          }
                          alt="First slide"
                          style={{
                            width: "100%",
                            height:
                              this.context.theme.carouselImageHeightMobile +
                              "cm",
                            // height: window.innerHeight / 3,
                          }}
                        />
                      </Nav.Link>
                    </Carousel.Item>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export class ShowMainCategoryMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  onMainCategoryClickEvent = (event) => {
    this.context.GetProductsByMainCategoryId(event.currentTarget.id);
  };
  render() {
    const settings = {
      lazyLoad: true,
      arrows: true,
      // dots: true,

      speed: 500,
      slidesToShow: Math.round(
        window.innerWidth /
          (this.context.theme.mainCategorySlideWidthMobile == undefined
            ? 4 * 37
            : this.context.theme.mainCategorySlideWidthMobile * 37),
        0
      ),
      infinite: false,
      autoplay: false,
      slidesToScroll: 2,
      rows: 2,
      // centerMode: true,
      // infinite: false,
      // variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "react__slick__slider__parent",
    };
    return (
      <>
        {this.context.theme.hideMainCategoryMobile == false &&
        this.context.mainCategories.length > 0 ? (
          <>
            <div
              className="my-5 p-0 CornersRounded"
              style={{
                backgroundColor: this.context.theme.mainCategoryBackgroundColor
                  ? this.context.theme.mainCategoryBackgroundColor
                  : this.context.theme.homepageBackgroundColor,
                color: this.context.theme.mainCategoryTextColor
                  ? this.context.theme.mainCategoryTextColor
                  : this.context.theme.homepageTitlesTextColor,
              }}
            >
              <h4
                className={
                  " producttitlefontandsizeDesktop " +
                  this.context.theme.homepageTitlesAlign
                }
                style={{
                  fontSize:
                    this.context.theme.homepageTitlesFontSizeMobile + "px",
                  // color: this.context.theme.homepageTitlesTextColor,
                  textTransform: this.context.theme.homepageTitlesTextCase,
                }}
              >
                <h1 class="bold_font ">
                  {this.context.theme.mainCategoryNameMobile}
                </h1>
                <span
                  // className="text-secondary"
                  style={{
                    textTransform: "lowercase",
                    fontSize: "12px",
                  }}
                >
                  {this.context.theme.hideProductCount == "false" ? (
                    <>- [{this.context.prodsAll.length} Products]</>
                  ) : (
                    ""
                  )}
                </span>
              </h4>

              <div className="border-0 row justify-content-around py-0 mx-2">
                <Slider {...settings}>
                  {this.context.mainCategories
                    .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                    .map((maincategory) => (
                      <>
                        <div
                          className="card py-1 mx-0 border-0"
                          style={{
                            width:
                              this.context.theme.mainCategoryCardWidthMobile +
                              "cm",
                            backgroundColor: this.context.theme
                              .mainCategoryBackgroundColor
                              ? this.context.theme.mainCategoryBackgroundColor
                              : this.context.theme.homepageBackgroundColor,
                            color: this.context.theme.mainCategoryTextColor
                              ? this.context.theme.mainCategoryTextColor
                              : this.context.theme.homepageTitlesTextColor,
                          }}
                        >
                          <div className="text-center imgcenter ">
                            <Nav.Link
                              as={Link}
                              id={maincategory.id}
                              className=" "
                              to="/ProductsListMobile"
                              onClick={(e) => {
                                this.onMainCategoryClickEvent(e);
                              }}
                              style={{
                                // color: this.context.theme.categoryTextColor,
                                // backgroundColor:
                                //   this.context.theme.homepageBackgroundColor,
                                backgroundColor: this.context.theme
                                  .mainCategoryBackgroundColor
                                  ? this.context.theme
                                      .mainCategoryBackgroundColor
                                  : this.context.theme.homepageBackgroundColor,
                                color: this.context.theme.mainCategoryTextColor
                                  ? this.context.theme.mainCategoryTextColor
                                  : this.context.theme.homepageTitlesTextColor,
                              }}
                            >
                              <img
                                src={
                                  this.context.store.storageBlobUrl +
                                  this.context.store.storageBlobContainerName +
                                  "/images/" +
                                  maincategory.imgFileName +
                                  this.context.store.storageSasToken
                                }
                                className={
                                  // "btn3d-default " +
                                  this.context.theme.mainCategoryDisplayShape
                                }
                                alt="..."
                                style={{
                                  cursor: "pointer",
                                  width:
                                    this.context.theme
                                      .maincategoryImageWidthMobile + "cm",
                                  height:
                                    this.context.theme
                                      .mainCategoryImageHeightMobile + "cm",
                                }}
                              ></img>
                              <label
                                className="my-3"
                                style={{
                                  width: "100%",
                                  height:
                                    this.context.theme
                                      .maincategoryNameHeightMobile + "cm",
                                  cursor: "pointer",
                                  color: this.context.theme
                                    .mainCategoryTextColor
                                    ? this.context.theme.mainCategoryTextColor
                                    : this.context.theme
                                        .homepageTitlesTextColor,
                                }}
                              >
                                <b>{maincategory.name}</b>{" "}
                              </label>
                            </Nav.Link>
                            <span
                              class="notify-maincategory-mobile-badge-right_share"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Share me"
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  this.context.theme.mainNavBarBackgroundColor,
                                color: this.context.theme.mainNavBarTextColor,
                              }}
                            >
                              <RWebShare
                                data={{
                                  text: maincategory.name,
                                  url:
                                    window.location.origin +
                                    "/MainCategoryArg?name=" +
                                    maincategory.name,
                                  title: maincategory.name,
                                }}
                                onClick={() =>
                                  console.log("shared successfully!")
                                }
                              >
                                <FaShareAlt size="14" />
                              </RWebShare>
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                </Slider>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export class Top5AdsMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  OnAdClick = (e, ad) => {
    this.context.UpdateReloadProductListView("true");
    if (ad.adType == 1) {
      //main category
      this.context.GetProductsByMainCategoryId(ad.mainCategoryId);
    } else if (ad.adType == 2) {
      //sub category

      this.context.GetProductsBySubCategoryId(ad.subCategoryId, null, null);
    } else if (ad.adType == 3) {
      this.context.GetProductsByProductgroupId(ad.productGroupId);
    }
  };
  GetRedirectPathForProductMenu(menu) {
    if (menu.productGroupId != null) return "/ProductsListMobile";
    if (menu.mainCategoryId != null) return "/ProductsListMobile";
    if (menu.subCategoryId != null) return "/ProductsListMobile";
  }

  render() {
    return (
      <>
        {this.context.top5Ads.length > 0 ? (
          <>
            <div className="m-0 p-0 w-100 border-2 border-dark">
              {this.context.top5Ads.map((m) => (
                <>
                  <div className="m-0 p-0 w-100 border-5 border-dark">
                    <Nav.Link
                      as={Link}
                      className="m-2 p-0"
                      to={this.GetRedirectPathForProductMenu(m)}
                      exact
                    >
                      <div className="img-hover-zoom m-0 p-0 w-100 border-2 border-dark">
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            m.imgFileName +
                            this.context.store.storageSasToken
                          }
                          className=" CornersRounded"
                          alt="..."
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          onClick={(e) => {
                            this.OnAdClick(e, m);
                          }}
                        ></img>
                      </div>
                    </Nav.Link>
                  </div>
                </>
              ))}
            </div>
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export class ShowSubCategoryMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  onSubCategoryClickEvent = (event) => {
    this.context.GetProductsBySubCategoryId(event.currentTarget.id, null, null);
  };
  render() {
    const settingsAllCategory = {
      lazyLoad: true,
      arrows: true,
      // dots: true,
      infinite: false,
      autoplay: false,
      speed: 500,
      slidesToShow: Math.round(
        window.innerWidth /
          (this.context.theme.subCategorySlideWidthMobile == undefined
            ? 4 * 37
            : this.context.theme.subCategorySlideWidthMobile * 37),
        0
      ),
      slidesToScroll: 2,
      rows: 2,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "react__slick__slider__parent",
    };
    return (
      <>
        {this.context.theme.hideSubCategoryMobile == false &&
        this.context.subCategories.length > 0 ? (
          <>
            <div
              className="my-5 p-1 border-0 CornersRounded"
              style={{
                backgroundColor: this.context.theme.subCategoryBackgroundColor
                  ? this.context.theme.subCategoryBackgroundColor
                  : this.context.theme.homepageBackgroundColor,
              }}
            >
              <h3
                className={
                  "producttitlefontandsizeDesktop " +
                  this.context.theme.homepageTitlesAlign
                }
                style={{
                  fontSize:
                    this.context.theme.homepageTitlesFontSizeMobile + "px",
                  color: this.context.theme.subCategoryTextColor
                    ? this.context.theme.subCategoryTextColor
                    : this.context.theme.homepageTitlesTextColor,
                  textTransform: this.context.theme.homepageTitlesTextCase,
                }}
              >
                <h1 class="bold_font ">
                  {this.context.theme.subCategoryNameMobile}
                </h1>
                {this.context.theme.hideProductCount == "false" ? (
                  <>
                    <span
                      className="text-secondary"
                      style={{
                        textTransform: "lowercase",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      - [{this.context.prodsAll.length} Products]
                    </span>
                  </>
                ) : (
                  ""
                )}
              </h3>
              <div className="">
                <Slider {...settingsAllCategory}>
                  {this.context.subCategories
                    .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                    .map((subcategory) => (
                      <>
                        <div
                          className="card py-1 col-3 mb-0 mx-2 border-0"
                          style={{
                            width:
                              this.context.theme.subCategoryCardWidthMobile +
                              "cm",
                            backgroundColor: this.context.theme
                              .subCategoryBackgroundColor
                              ? this.context.theme.subCategoryBackgroundColor
                              : this.context.theme.homepageBackgroundColor,
                          }}
                        >
                          <div
                            className="text-center imgcenter"
                            // style={{
                            //   backgroundColor:
                            //     this.context.theme.homepageBackgroundColor,
                            // }}
                          >
                            <Nav.Link
                              as={Link}
                              id={subcategory.id}
                              className="navBarLink text-center align-center"
                              to="/ProductsListMobile"
                              onClick={(e) => {
                                this.onSubCategoryClickEvent(e);
                              }}
                            >
                              <img
                                src={
                                  this.context.store.storageBlobUrl +
                                  this.context.store.storageBlobContainerName +
                                  "/images/" +
                                  subcategory.imgFileName +
                                  this.context.store.storageSasToken
                                }
                                // className="rounded-pill"
                                className={
                                  // "btn3d-default-custom " +
                                  this.context.theme
                                    .subCategoryAndBrandDisplayShape
                                }
                                alt="..."
                                style={{
                                  cursor: "pointer",
                                  width:
                                    this.context.theme
                                      .subCategoryImageHeightMobile + "cm",
                                  height:
                                    this.context.theme
                                      .subCategoryImageHeightMobile + "cm",
                                }}
                              ></img>
                              <label
                                className="my-3"
                                style={{
                                  width: "100%",
                                  cursor: "pointer",
                                  color: this.context.theme.subCategoryTextColor
                                    ? this.context.theme.subCategoryTextColor
                                    : this.context.theme
                                        .homepageTitlesTextColor,
                                  height:
                                    this.context.theme
                                      .subcategoryNameHeightMobile + "cm",
                                }}
                              >
                                <h6 className="text-center">
                                  <b className="">{subcategory.name}</b>{" "}
                                </h6>
                              </label>
                            </Nav.Link>
                            <span
                              class="notify-subcategory-mobile-badge-right_share"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Share me"
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  this.context.theme.mainNavBarBackgroundColor,
                                color: this.context.theme.mainNavBarTextColor,
                              }}
                            >
                              <RWebShare
                                data={{
                                  text: subcategory.name,
                                  url:
                                    window.location.origin +
                                    "/SubCategoryArg?name=" +
                                    subcategory.name,
                                  title: subcategory.name,
                                }}
                                onClick={() =>
                                  console.log("shared successfully!")
                                }
                              >
                                <FaShareAlt size="14" />
                              </RWebShare>
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                </Slider>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export class ShowProductGroupsMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  onViewAllProductGroupClickEvent = (event) => {
    this.context.GetProductsByProductgroupId(event.currentTarget.id);
  };

  render() {
    const settingsProductGroup = {
      lazyLoad: true,
      arrows: true,
      // dots: true,
      infinite: false,
      // autoplay: true,
      speed: 1000,
      slidesToShow: Math.round(
        window.innerWidth /
          (this.context.theme.productGropupSlideWidthMobile == undefined
            ? 4 * 37
            : this.context.theme.productGropupSlideWidthMobile * 37),
        0
      ),
      slidesToScroll: 2,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "react__slick__slider__parent1",
    };
    return (
      <>
        {this.props.reload == false ? (
          <>
            {this.context.prodsAll.length > 0
              ? this.context.productGroups
                  .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                  .map((pg) =>
                    pg.productGroupAndProductsMaps.length > 0 ? (
                      <>
                        <div className="my-5 p-0 border-0">
                          <div>
                            <h2
                              style={{
                                fontSize:
                                  this.context.theme
                                    .homepageTitlesFontSizeMobile + "px",
                                color:
                                  this.context.theme.homepageTitlesTextColor,
                                textTransform:
                                  this.context.theme.homepageTitlesTextCase,
                              }}
                              className={
                                "producttitlefontandsizeDesktop " +
                                this.context.theme.homepageTitlesAlign
                              }
                            >
                              <h1 class="bold_font ">{pg.name} </h1>
                              {this.context.theme.hideProductCount ==
                              "false" ? (
                                <>
                                  <span
                                    className="text-secondary"
                                    style={{
                                      textTransform: "lowercase",
                                      fontSize: "12px",
                                    }}
                                  >
                                    - [
                                    {
                                      this.context.productGroups.filter(
                                        (f) => f.name == pg.name
                                      )[0].productGroupAndProductsMaps.length
                                    }{" "}
                                    Products]
                                  </span>
                                </>
                              ) : (
                                ""
                              )}

                              <h5>
                                <Button
                                  as={Link}
                                  id={pg.id}
                                  to="/ProductsListMobile"
                                  className="text-end p-2 border-0"
                                  onClick={(e) => {
                                    this.onViewAllProductGroupClickEvent(e);
                                  }}
                                  style={{
                                    backgroundColor:
                                      this.context.theme
                                        .mainNavBarBackgroundColor,
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                    textTransform: "none",
                                    fontSize: "12px",
                                  }}
                                >
                                  View All
                                </Button>
                              </h5>
                            </h2>
                          </div>
                          <div className="py-2 m-0 ">
                            <Slider {...settingsProductGroup}>
                              {pg.productGroupAndProductsMaps != undefined
                                ? pg.productGroupAndProductsMaps
                                    .sort((a, b) =>
                                      a.seqNo > b.seqNo ? 1 : -1
                                    )
                                    .map((map) =>
                                      this.context.prodsAll.find(
                                        (f) => f.id == map.productId
                                      ) != undefined ? (
                                        <>
                                          <div
                                            className="card p-0 mx-2 mb-3 CornersRounded text-center"
                                            style={{
                                              width:
                                                this.context.theme
                                                  .productCardWidthMobile +
                                                "cm",
                                              backgroundColor:
                                                this.context.theme
                                                  .productCardBackgroundColor,
                                              borderColor:
                                                this.context.theme
                                                  .productCardBorderColor,
                                            }}
                                          >
                                            <div className=" text-center m-0 p-0 pb-0 CornersRounded ">
                                              <ProductCardMobile
                                                prod={this.context.prodsAll.find(
                                                  (f) => f.id == map.productId
                                                )}
                                              ></ProductCardMobile>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    )
                                : ""}
                            </Slider>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  )
              : ""}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export class ShowBrandsMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  onBrandClickEvent = (event) => {
    this.context.GetProductsByBrandId(event.currentTarget.id);
  };

  render() {
    const settingsAllCategory = {
      lazyLoad: true,
      arrows: true,
      // dots: true,
      infinite: false,
      autoplay: false,
      speed: 1000,
      slidesToShow: Math.round(
        window.innerWidth /
          (this.context.theme.subCategorySlideWidthMobile == undefined
            ? 4 * 37
            : this.context.theme.subCategorySlideWidthMobile * 37),
        0
      ),
      slidesToScroll: 2,
      rows: 2,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      className: "react__slick__slider__parent",
    };
    return (
      <>
        {this.context.portalMode == "CustomerPortal" ? (
          <>
            {this.context.brands.length > 0 ? (
              <>
                <div className="my-4 p-1">
                  <h3
                    className={
                      "producttitlefontandsizeDesktop " +
                      this.context.theme.homepageTitlesAlign
                    }
                    style={{
                      fontSize:
                        this.context.theme.homepageTitlesFontSizeMobile + "px",
                      color: this.context.theme.homepageTitlesTextColor,
                      textTransform: this.context.theme.homepageTitlesTextCase,
                    }}
                  >
                    <h1 class="bold_font ">
                      {this.context.store.businessTypeId == 20
                        ? "Browse by Artist"
                        : "Shop by Brands"}
                    </h1>
                    {this.context.theme.hideProductCount == "false" ? (
                      <>
                        <span
                          className=""
                          style={{
                            textTransform: "lowercase",
                            fontSize: "12px",
                          }}
                        >
                          {" "}
                          - [
                          {
                            this.context.prodsAll.filter(
                              (f) => f.brandId != null
                            ).length
                          }{" "}
                          Products]
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </h3>
                  <div className=" ">
                    <Slider {...settingsAllCategory}>
                      {this.context.brands
                        //.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                        .map((brand) => (
                          <>
                            <div
                              className="card py-1  col-3 mb-1 mx-2 border-0"
                              style={{
                                width:
                                  this.context.theme
                                    .subCategoryCardWidthMobile + "cm",
                                backgroundColor:
                                  this.context.theme.homepageBackgroundColor,
                              }}
                            >
                              <div
                                className="text-center imgcenter"
                                style={
                                  {
                                    // backgroundColor:
                                    //   this.context.theme
                                    //     .productCardBackgroundColor,
                                  }
                                }
                              >
                                <Nav.Link
                                  as={Link}
                                  id={brand.id}
                                  className="navBarLink text-center align-center"
                                  to="/ProductsListMobile"
                                  onClick={(e) => {
                                    this.onBrandClickEvent(e);
                                  }}
                                >
                                  <img
                                    src={
                                      this.context.store.storageBlobUrl +
                                      this.context.store
                                        .storageBlobContainerName +
                                      "/images/" +
                                      brand.imgFileName +
                                      this.context.store.storageSasToken
                                    }
                                    // className="rounded-circle btn3d-default-custom"
                                    className="rounded-circle "
                                    alt="..."
                                    style={{
                                      cursor: "pointer",
                                      width:
                                        this.context.theme
                                          .subCategoryImageHeightMobile ==
                                        undefined
                                          ? "0cm"
                                          : this.context.theme
                                              .subCategoryImageHeightMobile +
                                            "cm",
                                      height:
                                        this.context.theme
                                          .subCategoryImageHeightMobile ==
                                        undefined
                                          ? "0cm"
                                          : this.context.theme
                                              .subCategoryImageHeightMobile +
                                            "cm",
                                    }}
                                  ></img>
                                  <label
                                    style={{
                                      width: "100%",
                                      cursor: "pointer",
                                      color:
                                        this.context.theme.categoryTextColor,
                                    }}
                                  >
                                    <h6 className="text-center my-3  ">
                                      <b>{brand.brandName}</b>{" "}
                                    </h6>
                                  </label>
                                </Nav.Link>
                              </div>
                            </div>
                          </>
                        ))}
                    </Slider>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

function SampleNextArrow(props) {
  const { theme } = useContext(CartContext);
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: theme.sliderArrowBackgroundColor,
        color: "black",
      }}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { theme } = useContext(CartContext);
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: theme.sliderArrowBackgroundColor,
        color: "black",
      }}
      onClick={onClick}
    />
  );
}
