import { Component, useContext, useState } from "react";
import { ProductSingleProductView } from "./ProductSingleProductView";
import {
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
  Nav,
  Offcanvas,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CartContext from "./CartContext";
import IncDecCounter from "./IncDecCounterControl";
import Swal from "sweetalert2";

export default class WishListView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      show: true,
      showSingleView: false,
      ProdSingleView: "",
    };
  }
  forceUpdate = () => {
    this.setState({ value: 0 });
    return () => this.setState({ value: this.state.value + 1 }); // update the state to force render
  };

  OpenSingleView = (prod) => {
    this.setState({ ProdSingleView: prod }, () => {
      this.setState({ showSingleView: true });
    });
  };
  editModalClose = () => {
    this.setState({ showSingleView: false }, () => {
      this.context.forceUpdateCartView();
    });
  };

  isMainCategoryHasItems = (maincategory) => {
    var result = false;

    this.context.myCart.filter(
      (item) => item.prod.mainCategoryId === maincategory.id
    ).length > 0
      ? (result = true)
      : (result = false);

    return result;
  };

  ValidateProductInstockAvailablity = () => {
    var wishlist = [...this.context.wishListProductIds];
    var newwishlist = [...this.context.wishListProductIds];
    wishlist.map((p, i) => {
      var prd = this.context.prodsAll.filter((f) => f.id == p.prodId);
      if (prd.length == 1) {
        //ok
      } else {
        //TODO:does not delete more than one record
        this.context.DeleteProductOnWishList(p.prodId);
        //newwishlist = newwishlist.filter((productitem) => p.prodId != productitem.prodId)
      }
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.ValidateProductInstockAvailablity();
    this.context.UpdateOrderStatus("");
  }
  getWishListItems = () => {
    // this.context.wishListProductIds;
  };

  handleMenuOffCanvasClose = () => {
    this.setState({ show: false });
    this.props.onCloseWishList();
  };
  handleMenuOffCanvasShow = (e) => {
    this.setState({ show: true });
  };

  getProduct = (prodId) => {
    var prod = this.context.prodsAll.filter((p) => p.id == prodId);
    return prod;
  };
  addToCartSubmit = (prod) => {
    this.context.addToMyCart({
      prod: prod,
      qty: 1,
    });
  };

  render() {
    return (
      <>
        {this.state.showSingleView == true ? (
          <ProductSingleProductView
            show={this.state.showSingleView}
            onHide={this.editModalClose}
            prod={this.state.ProdSingleView}
          />
        ) : (
          ""
        )}
        <Offcanvas
          show={this.state.show}
          onHide={this.handleMenuOffCanvasClose}
          placement="end"
          className="h-auto w-50"
        >
          <Offcanvas.Header
            closeButton
            // className="bg-danger text-white"
            style={{
              backgroundColor: "white",
              color: "black",
            }}
          >
            <Offcanvas.Title>
              <h5>My wish list...</h5>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-light text-dark">
            <Table border className="cartviewtable px-5" responsive="md">
              <thead>
                <tr className="bg-light text-start text-secondary">
                  <th width="10%"></th>
                  <th width="35%"></th>
                  <th width="15%" className="text-center">
                    Price
                  </th>
                  <th width="10%" className="text-center">
                    Discount
                  </th>
                  <th width="20%"></th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {this.context.wishListProductIds.map((wishListProductId) => (
                  <>
                    {this.getProduct(wishListProductId.prodId).length > 0 ? (
                      <tr>
                        <td>
                          <img
                            src={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.getProduct(wishListProductId.prodId)[0]
                                .imgFileName +
                              this.context.store.storageSasToken
                            }
                            className="cardprodimg-on-cartview mx-auto"
                            alt="..."
                          />
                        </td>
                        <td>
                          {this.getProduct(wishListProductId.prodId)[0].name}
                        </td>
                        <td className="text-start">
                          <b>
                            {Math.round(
                              Number(
                                this.getProduct(wishListProductId.prodId)[0]
                                  .mrp -
                                  (this.getProduct(wishListProductId.prodId)[0]
                                    .mrp *
                                    this.getProduct(wishListProductId.prodId)[0]
                                      .discount) /
                                    100
                              ),
                              0
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </b>
                          &nbsp;&nbsp;&nbsp;
                          <small>
                            <strike>
                              {this.getProduct(
                                wishListProductId.prodId
                              )[0].mrp.toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </strike>
                          </small>
                        </td>
                        <td className="text-center">
                          {
                            this.getProduct(wishListProductId.prodId)[0]
                              .discount
                          }
                          %
                        </td>
                        <td className="text-center">
                          {this.getProduct(wishListProductId.prodId)[0]
                            .productVariantType == 2 ? (
                            <>
                              <Button
                                className="align-content-center w-100 border buttonRounded buttonwithshadow"
                                style={{
                                  backgroundColor:
                                    this.context.theme
                                      .mainNavBarBackgroundColor,
                                  color: this.context.theme.mainNavBarTextColor,
                                }}
                                onClick={(e) =>
                                  this.OpenSingleView(
                                    this.getProduct(wishListProductId.prodId)[0]
                                  )
                                }
                              >
                                View
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                // variant="primary"
                                className="align-content-center addtocartbutton "
                                type="submit"
                                size="lg"
                                onClick={(e) =>
                                  this.addToCartSubmit(
                                    this.getProduct(wishListProductId.prodId)[0]
                                  )
                                }
                                style={{
                                  backgroundColor:
                                    this.context.theme
                                      .mainNavBarBackgroundColor,
                                  color: this.context.theme.mainNavBarTextColor,
                                }}
                              >
                                Add to &nbsp;
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="currentColor"
                                  class="bi bi-cart"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                              </Button>
                            </>
                          )}
                        </td>
                        <td className="col-md-1">
                          <OverlayTrigger overlay={<Tooltip>Delete!</Tooltip>}>
                            <Button
                              variant="outline-danger"
                              onClick={(e) => {
                                Swal.fire({
                                  title: "Warning!",
                                  confirmButtonColor: "#23B14D",
                                  cancelButtonColor: "#23B14D",
                                  text: "Do you want to delete?",
                                  showCancelButton: "true",
                                  confirmButtonText: "Yes",
                                  cancelButtonText: "No",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    this.context.DeleteProductOnWishList(
                                      wishListProductId.prodId
                                    );
                                  }
                                });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </tbody>
            </Table>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}
